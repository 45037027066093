*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
}

.card {
  background-color: black;
  padding: 1rem;
  height: 30rem;
  border-radius: 0.5rem;
  align-items: center;
  gap: 1rem;
}

.password-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.password {
  background-color: white;
  min-width: 10rem;
  height: 3rem;
  padding: 0.7rem;
  font-size: 1.3rem;
  color: black;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.password::before {
  content: attr(data-text);
  position: absolute;
  transform: translate(0, -130%);
  background-color: azure;
  padding: 0.3rem;
  border-radius: 0.5rem;
  color: black;
  opacity: 0;
  z-index: 1;
  transition: all 0.2s ease-in;
}

.password:hover::before {
  opacity: 1;
}

.password-length {
  width: 3rem;
  text-align: center;
  border: none;
  padding: 0.3rem;
  border-radius: 0.5rem;
  font-size: 1rem;
}

.heading {
  text-align: center;
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.btn-checkbox {
  cursor: pointer;
}

.password-field {
  width: 20rem;
  padding: 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 0.5rem;
}

.label {
  font-size: 1.2rem;
  font-weight: bold;
}

.btn-container {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  margin: 1.3rem;
}

.btn {
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.3rem;
  background-color: whitesmoke;
  cursor: pointer;
}
